import React from 'react';
import Headline from '../../../components/Headeline';
import Comparatives from '../../../helpers/Comparatives';
import utils from '../../../helpers/utils';

import './styles.css';

export default function Content () {
    const _comparatives = new Comparatives();
    const listData = _comparatives.getComparativesProperties()

    return (
        <div id="comparatives">            
            <Headline 
                title={ _comparatives.getQtdComparativesProperties() > 0 ? 'Escolha o imóvel ideal para você' : 'Você ainda não adicionou imóveis'}
                text=""
            />
            <div className="container">

                {_comparatives.getQtdComparativesProperties() > 0 ?
                    <div className="content">
                        <div className="card-comparatives col-main">
                            <ul>
                                <li className="title">Informações do Imóvel</li>
                                <li>Código</li>
                                <li>Oferta</li>
                                <li>Tipo de imóvel</li>
                                <li>Bairro</li>
                                <li>Endereço</li>
                                <li>Área privativa</li>
                                <li>Dormitórios</li>
                                <li>Banheiros</li>
                                <li>Vagas de garagem</li>
                                <li className="title">Preço do Imóvel</li>
                                <li>Valor</li>
                                <li>Tipo</li>
                                <li className="title">Remover Imóvel</li>
                                <li>Clique no botão para remover</li>
                            </ul>
                        </div>

                        {listData.map((card, index) => (                        
                            <div key={index} id={`card-comparatives-${card.id}`} className="card-comparatives">
                                <ul>
                                    <li><img src={card.foto} alt={card.titulo}/></li>
                                    <li className="code">{card.codigo}</li>
                                    <li>{utils.capitalize(card.modalidade)}</li>
                                    <li>{card.tipo}</li>
                                    <li>{card.bairro}</li>                                    
                                    <li>{card.endereco}</li>                                  
                                    <li>{card.area === 0 ? card.area_total.replace(',00','') : card.area.replace(',00','')}m²</li>                                
                                    <li>{card.quartos + card.suites}</li>                              
                                    <li>{card.banheiros}</li>                           
                                    <li>{card.vagas}</li>
                                    <li className="clear"></li>
                                    <li>R$ {card.valor.replace(',00','')}</li>                     
                                    <li> { utils.capitalize(card.modalidade) === "Aluguel" ? "Mensal" : "--"} </li>                
                                    <li className="clear"></li>           
                                    <li className="remove-comparate" onClick={() => _comparatives.remove(card)}>
                                        <i className="fas fa-trash"/> &nbsp;&nbsp;remover
                                    </li>                                    
                                </ul>
                            </div>
                        ))}                        
                    </div>
                : 
                    <div className="no-comparatives">                      
                        <h2>Como adicionar um imóvel na página de comparativos?</h2>                   
                        <p>Na página de busca ou na página do imóvel, clique no <strong>botão <i className="fa fa-clone" /></strong>&nbsp;
                        para adicionar na página de comparativos.</p>
                    </div>
                }

            </div>
        </div>
    );

}