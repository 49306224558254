import React from 'react';
import './styles.css';

import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    EmailIcon
  } from "react-share";

const Share = () => {
    return (
        <div className="box-share">
            <FacebookShareButton 
                children={<FacebookIcon size={40} round={true} />} 
                url={window.location.href} />

            <TwitterShareButton 
                children={<TwitterIcon size={40} round={true} />} 
                url={window.location.href} />

            <WhatsappShareButton 
                children={<WhatsappIcon size={40} round={true} />}
                windowWidth={700}
                windowHeight={700}
                url={window.location.href} />

            <EmailShareButton 
                children={<EmailIcon size={40} round={true} />} 
                url={window.location.href} />
        </div>
    );
}

export default Share;