import React from 'react';
import { FiTrello, FiMapPin  } from 'react-icons/fi';
import { Input } from "reactstrap";

import './styles.css';

export default function Topo(props) {
    const [viewMap, setViewMap] = React.useState();
    const [openForm, setOpenForm] = React.useState();

    const handleChangeForm = () => {
        setOpenForm(!openForm);
        setViewMap(false);
        if (!openForm) {
            document.querySelector('#form-sidebar').classList.remove('hidden-form');
            document.querySelector('#result-card').classList.remove('view-map'); 
            document.querySelector('#result-map').classList.add('hidden-map');
        } else {
            document.querySelector('#form-sidebar').classList.add('hidden-form');
        }
    }

    const handleChangeMap = () => {
        setViewMap(!viewMap);
        setOpenForm(false);
        if (!viewMap) {            
            document.querySelector('#result-card').classList.add('view-map');       
            document.querySelector('#result-map').classList.remove('hidden-map');
            document.querySelector('#form-sidebar').classList.add('hidden-form');       
        } else {
            document.querySelector('#result-map').classList.add('hidden-map');
            document.querySelector('#result-card').classList.remove('view-map'); 
        }
    };

    const openSidebarForm = () => {
        document.querySelector('#overlay').classList.add('overlay');
        document.querySelector('#sidebar-form').classList.add('open-sidebar-form');          
    }

    const openSidebarMap = () => {
        document.querySelector('#overlay').classList.add('overlay');
        document.querySelector('#sidebar-map').classList.add('open-sidebar-map');          
    }

    return (
        <div id="search-result-topo">
            <div className="container">
                <div className="content">
                    <button className="btn-filtros-mobile" onClick={openSidebarForm}>
                        <FiTrello /> &nbsp;Filtros
                    </button>

                    <button                
                        type="button" 
                        className={`btn-filtros ${openForm ? 'active':''}`}
                        onClick={handleChangeForm} >
                            <FiTrello /> &nbsp;Filtros
                    </button>

                    <Input
                        type="select"
                        name="order"
                        onChange={(event)=>props.handleSelect(event)}
                        value={props.order} >
                            <option value="0">Ordenar por</option>                   
                            <option value="menor-preco">Menor preço</option>
                            <option value="maior-preco">Maior preço</option>
                            <option value="menor-area">Menor área</option>
                            <option value="maior-area">Maior área</option>
                            <option value="destaque">Destaques</option>
                    </Input>

                    <button
                        type="button" 
                        className={`btn-map ${viewMap ? 'active':''}`}
                        onClick={handleChangeMap } >
                            <FiMapPin /> &nbsp;Mapa
                    </button> 

                    <button className="btn-map-mobile" onClick={openSidebarMap} >
                            <FiMapPin /> &nbsp;Mapa
                    </button>
                </div>
            </div>
        </div>
    );
}