import * as constants from '../../config/constants';
import ApiService from './../../services/api';
import RequestUtils from '../../config/requestUtils';

export default class Services {
    busca(
            modalidade,
            tipo,
            endereco,
            filtros = [],
            page, order = 'menor-valor',
            limit = 12,
            offset = 0,
            caracSelecionados = [],
            caracSelecionadosCondominio = []
        ){

        const api = ApiService.create(modalidade);
        let currentPage = (page ? page : 1);

        let url = "";
        let method = RequestUtils.methodResolver('busca', modalidade);

        url += `${method}?limit=${limit}&offset=${offset}&page=${currentPage}`;

        if (tipo) {
            url += `&tipo=${tipo.replace('-', ' ')}`;
        }        

        if (endereco) {
            url += `&endereco=${endereco}`;
        }

        if (filtros) {
            for (let i in filtros) {
                if (
                filtros[i] === '' || filtros[i] === null || filtros[i] === undefined || filtros[i] === 0) 
                continue;
                url += `&${i}=${filtros[i]}`;
            }
        }

        if (order) {
            url += `&order=${order}`;
        }

        if (caracSelecionados.length > 0) {
            let list = caracSelecionados.map(item => [
                item.value
            ]);
            url += `&caracteristicas=${list}`;
        }
        
        if (caracSelecionadosCondominio.length > 0) {   
            let list = caracSelecionadosCondominio.map(item => [
                item.value
            ]);
            url += `&caracsCondominio=${list}`;
        }

        return api.get(url)
            .then(response => {                
                if (!response.status === 200) {
                    throw response;
                }

                return response.data.resultado;
            })
            .catch(e => console.log(e));;
    };

    getCaracteristicasImovel(modalidade, tipo) {
        const api = ApiService.create(modalidade);
        
        return api.get(constants.METODOS[modalidade].caracteristicas + tipo)
        .then(response => {                
            if (!response.status === 200) {
                throw response;
            }

            return response.data.options;
        })
        .catch(e => console.log(e));;
    }

    getCaracteristicasCondominio(modalidade, tipo) {
        const api = ApiService.create(modalidade);
        
        return api.get(constants.METODOS[modalidade].caracteristicasCondominio + tipo)
        .then(response => {                
            if (!response.status === 200) {
                throw response;
            }
            return response.data.options;
        })
        .catch(e => console.log(e));;
    }

    getTiposImoveisOptions(modalidade) {
        const api = ApiService.create(modalidade);
        
        return api.get(constants.METODOS[modalidade].tipos)
        .then(response => {                
            if (!response.status === 200) {
                throw response;
            }
            return response.data.options;
        })
        .catch(e => console.log(e));;
    }

    getbuscaEndereco(modalidade, palavra) {
        const api = ApiService.create(modalidade);
        
        return api.get(constants.METODOS[modalidade].buscaEndereco)
        .then(response => {                
            if (!response.status === 200) {
                throw response;
            }
            return response.data.resultado;
        })
        .catch(e => console.log(e));;
    }
}