import React from 'react';
import { FiShare } from 'react-icons/fi';
import Headline from '../../../components/Headeline';
import { Link } from 'react-scroll';

import './styles.css';

export default function Spread() {
    return (
        <section id='spread'>
            <Headline 
                title="Quero divulgar meu imóvel, como faço?" 
                text="Preencha o formulário do início desta página. E pronto.
                Entraremos em contato para agendar uma visita e seguir com os 
                demais detalhes da divulgação."
            />

            <Link 
                to="form-scroll-top" 
                smooth={true} 
                duration={800}
                offset={-145}
                className="btn btn-primary"                            
            >
                Anunciar meu imóvel &nbsp;
                <FiShare/>
            </Link>
            
        </section>
    );
}