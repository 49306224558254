import React from 'react';

import './styles.css';

export default function ContentHeadline({title, text}) {
    return (
        <div className="content-headline">
            <h2> {title} </h2>
            <p> {text} </p>
        </div>
    );
}