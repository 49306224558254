import React from 'react';
import Gallery from './Gallery';
import Details from './Details';
import Contact from './Contact';

import './styles.css';

export default function Content(props) {
    return (
        <div id='property-content'>           
            <Gallery data={props.imovel.imagens}/>
            <div className="block-details">
                <div className="content">
                    <Details imovel={props.imovel} />
                </div>
                <div className="contact">                 
                    <Contact 
                        imovel={props.imovel}                        
                        imovelProximos={props.imovelProximos}
                        handleLoadImovel={props.handleLoadImovel}
                     />
                </div>
            </div>
        </div>
    );
}