import React, { Fragment, Component } from 'react';
import Helmet from 'react-helmet';
import Header from '../../components/Layout/Header';
import Breadcrumbs from './Breadcrumbs';
import Topo from './Topo';
import Footer from '../../components/Layout/Footer';
import Widget from './Widgets';
import Options from './Options';
import WhatsApp from '../../components/Layout/Contato/Whatsapp';

export default class Company extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <Fragment>
                <Helmet title={`Sobre nós | ${process.env.REACT_APP_NAME}`} />
                <Header />
                <main>
                    <Breadcrumbs />
                    <Topo />
                    <Widget />
                    <Options />
                </main>
                <Footer />
                <WhatsApp />
            </Fragment>
        );
    }
}