import React, { useState } from 'react';
import MaskedInput from 'react-text-mask';
import Swal from 'sweetalert2';
import { Form, Input, Button } from 'reactstrap';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import api from '../../../../services/api';

import './styles.css';

const FormContact = (props) => {
    const [state, setState] = useState({
        nome: '',
        email: '',
        celular: '',
        mensagem: `Olá, gostaria de receber mais informações sobre o imóvel no bairro ${props.bairro}, ${props.cidade} - ${props.estado}. O código do imóvel é ${props.codigo}. Aguardo retorno.`,
        buttonStatus: true,
        declaracao_1: false,
        declaracao_2: false
    });

    const handleOnChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        });
    }

    const handleOnChangeCheckbox = (e) => {
        const stateObj = {
            ...state,
            [e.target.name]: e.target.checked,
            buttonStatus: true
        };

        if (stateObj.declaracao_1 === true && stateObj.declaracao_2 === true) {
            stateObj.buttonStatus = false;
        }

        setState(stateObj);
    }

    const clear = () => {
        setState({
            nome: '',
            email: '',
            celular: '',
            mensagem: `Olá, gostaria de receber mais informações sobre o imóvel no bairro ${props.bairro}, ${props.cidade} - ${props.estado}. O código do imóvel é ${props.codigo}. Aguardo retorno.` 
        })
    }

    const handleSubmitAgendamento = (event) => {
        event.preventDefault();
        // setLoading(true);
    
        let dados =
        {
            "codigo": props.codigo,
            "modalidade": props.modalidade,
            "link": window.location.href,
            "titulo": props.titulo,
            "nome": state.nome,
            "email": state.email,
            "celular": state.celular,
            "mensagem": state.mensagem
        }
    
        api.post('/email/solicitarInformacoesImovel', dados).then( response => {
            //   setLoading(false);

            if (response.data.code === 1) {
                  getAlert('success', 'Dados enviado com sucesso!');
                clear();
            }

            if (response.data.code === 2) {
                  getAlert('error', 'Ooops! Erro no envio dos dados.');
            }
        });
    }

    const getAlert = (icon, message) => {
        return Swal.fire({
            position: 'center',
            icon: icon,
            title: message,
            showConfirmButton: false,
            timer: 1500
        });
    }

    return (
        <Form className="form-contact" onSubmit={event => handleSubmitAgendamento(event)}>
            <Input 
                name="nome"
                type="text"
                placeholder="Seu nome"
                required={true}
                onChange={(event)=>handleOnChange(event)}
                value={state.nome} />

            <Input
                name="email"
                type="email"
                placeholder="Seu e-mail"
                required={true}
                onChange={(event)=>handleOnChange(event)}
                value={state.email} />

            <MaskedInput
                className="form-control"
                name='celular'
                mask={['(', /[1-9]/, /\d/, ')', ' ',/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                placeholder="Seu celular"                            
                guide={false}
                required={true}
                onChange={(event)=>handleOnChange(event)}
                value={state.celular} />

            <Input
                name="mensagem"
                type="textarea"
                required={true}
                rows="4"
                onChange={(event)=>handleOnChange(event)}
                value={state.mensagem} />

            <FormControlLabel
                control={<Checkbox checked={state.declaracao_1} onChange={(event) => handleOnChangeCheckbox(event)} name="declaracao_1" />}
                label="Declaro que li e concordo com a Política de Privacidade da DMV Imóveis."
            />

            <FormControlLabel
                control={<Checkbox checked={state.declaracao_2} onChange={(event) => handleOnChangeCheckbox(event)} name="declaracao_2" />}
                label="Concordo em receber comunicações da DMV Imóveis."
            />

            <br/><br/><p>Ao inserir os itens acima, a <b>DMV</b> demonstra a conformidade com a LGPD por meio da informação ao titular sobre a relização do tratamento de dados pessoais e o lead consente em receber comunicações da <b>DMV</b> sobre o anúncio.</p>

            <Button type="submit" disabled={state.buttonStatus}>Receber mais informações</Button>
        </Form>
    );
}

export default FormContact;