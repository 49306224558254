import React from 'react';
import { Link } from 'react-router-dom';

import './styles.css';

export default function SocialMedias() {
    return(
        <div className="box-social-medias">
            <Link to={{pathname: process.env.REACT_APP_FACEBOOK}} target="_blank" className='first-icon'>
                <i className="fab fa-facebook-f"/>
            </Link>
            <Link to={{pathname: process.env.REACT_APP_INSTAGRAM}} target="_blank">
            <i className="fab fa-instagram"/>
            </Link>
            <Link to={{pathname: process.env.REACT_APP_YOUTUBE}} target="_blank" className='last-icon'>
            <i className="fab fa-youtube"/>
            </Link>
        </div>
    );
}