import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import Header from '../../components/Layout/Header';
import Breadcrumbs from './Breadcrumbs';
import Topo from './Topo';
import Content from './Content';
import Footer from '../../components/Layout/Footer';
import api from '../../services/api';
import WhatsApp from '../../components/Layout/Contato/Whatsapp';

export default class Enterprises extends Component {
    state = {
        modalidade: this.props.match.params.modalidade,
        category: this.props.match.params.category,
        imoveis: [],
        limit: 12,
        offset: 0,
        page: 1,
        order:'',
        total: '',
        loadingContent: false,
        listCategory:[{'name': 'Qual a categoria', 'value': 0}]
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.modalidade !== this.props.match.params.modalidade) {
            this.setState({
                ...this.state,
                modalidade: this.props.match.params.modalidade,
                category: this.props.match.params.category,
                offset: 0,
                page: 1                
            }, () => {this.getEspecialCategory()})
        }
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        this.setState({loadingContent: true});
        this.getEspecialCategory();
    }

    handleOnSelect = (event) => {        
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        }, () => this.getEspecialCategory());
    }

    getEspecialCategory =  async() => {
        window.scrollTo(0, 0);
        this.getCategoryOptions();
        this.setState({loadingContent: true});
        await api.get(`${this.state.modalidade}/imoveis`, {
            params: { 
                category: this.state.category,
                limit: this.state.limit,
                offset: this.state.offset,
                page: this.state.page,
                order:this.state.order
            }
        }).then(response => {
            this.props.history.push(`/${this.state.modalidade}/categoria/${this.state.category}`);
            this.setState({
                ...this.state,
                imoveis: response.data.resultado.imoveis,
                total: response.data.resultado.total,
                loadingContent: false
            })
        });
    }

    getCategoryOptions = async() => {
        await api.get(`${this.state.modalidade}/categoryOptions`)
        .then(response => {
            this.setState({
                ...this.state,
                listCategory: response.data.options
            })
        })
    }

    handlePaginacao = (name, value) => {
        this.setState({ [name]: value }, () => {
            this.getEspecialCategory();            
        });
    };

    handlePageChange = (pageNumber) => {    
        this.setState({page: pageNumber});
        this.handlePaginacao('offset', ((this.state.limit * pageNumber) - this.state.limit))
    }

    render() {
        return (
            <Fragment>
                <Helmet title={`Lista de imóveis por categoria | ${process.env.REACT_APP_NAME}`} />
                <Header />
                <main>
                    <Breadcrumbs/>
                    <Topo
                        category={this.state.category}
                        order={this.state.order}
                        listCategory={this.state.listCategory}
                        handleOnSelect={this.handleOnSelect}/>
                    <Content 
                        {...this.state}
                        handlePageChange={this.handlePageChange}
                    />
                </main>
                <Footer />
                <WhatsApp />
            </Fragment>
        );            
    }
}