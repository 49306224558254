import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import TitleLoader from '../../components/TitleLoader';
import Header from '../../components/Layout/Header';
import Content from './Content';
import Alert from './Content/Alert';
import Footer from '../../components/Layout/Footer';
import ApiService from '../../services/api';
import WhatsApp from '../../components/Layout/Contato/Whatsapp';
import * as constants from '../../config/constants';

export default class PropertyDetails extends Component {
    state = {
        imovel: {tituloDetalhes:'...', imagens:[]},
        imoveisProximos: [],
        loadingContent: true
    }

    componentDidMount(){
        this.handleLoadImovel();
        window.scrollTo(0, 0);
    }
    
    handleLoadImovel = async(codigo = this.props.match.params.codigo, slug = this.props.match.params.slug) => {
        const api = ApiService.create(this.props.match.params.modalidade);

        window.scrollTo(0, 0);
        this.setState({loadingContent: true});
        await api.get(constants.METODOS[this.props.match.params.modalidade].detalhes + codigo).then(response => {
            if (response.data.imovel.tituloTextoDestaque ) {
                response.data.imovel.tituloDetalhes = response.data.imovel.tituloTextoDestaque;
            }

            this.setState({
                imovel: response.data.imovel,
                imovelProximos: response.data.imoveisPrx,
                loadingContent: false
            });
        })

        this.props.history.push(`/detalhes/${this.props.match.params.modalidade}/${codigo}${slug !== undefined ? `/${slug}`: ''}`);
    }

    render(){
        return (
            <Fragment>
                 <Helmet>
                    <title>{`${this.state.imovel.tituloDetalhes} | ${process.env.REACT_APP_NAME}`}</title>
                    <meta name="title" content={this.state.imovel.tituloDetalhes} />
                    <meta name="description" content={this.state.imovel.descricao} />

                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:title" content={this.state.imovel.tituloDetalhes} />
                    <meta property="og:description" content={this.state.imovel.descricao} />
                    <meta property="og:image" content={this.state.imovel.imagens !== undefined && this.state.imovel.imagens.length  > 0 ? this.state.imovel.imagens[0].imagem : ''} />
                    
                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content={window.location.href} />
                    <meta property="twitter:title" content={this.state.imovel.tituloDetalhes} />
                    <meta property="twitter:description" content={this.state.imovel.descricao} />
                    <meta property="twitter:image" content={this.state.imovel.imagens !== undefined && this.state.imovel.imagens.length > 0 ? this.state.imovel.imagens[0].imagem : ''} />
                </Helmet>
                <Header />
                    {this.state.imovel.id !== undefined ? 
                        <main>
                            <Content
                                imovel={this.state.imovel}
                                imovelProximos={this.state.imovelProximos} 
                                loadingContent={this.state.loadingContent}
                                handleLoadImovel={this.handleLoadImovel} 
                            />
                            <Alert />
                        </main>
                    : 
                        <main> 
                            <div id="property-content-error">
                                { this.state.loadingContent ? <TitleLoader /> :                      
                                <div className="card-error404">
                                    <h1>
                                        <i className="fa fa-unlink"></i>
                                        Oops! Imóvel não encontrado.
                                    </h1>
                                    <h2>
                                        Não encontramos o imóvel de código {this.props.match.params.codigo.toUpperCase()} que você está procurando. &nbsp;
                                        Pode ser que você tenha digitado o código do imóvel incorretamente.
                                    </h2>
                                    <Link to={'/'}>                        
                                        Clique aqui para volta e realizar nova busca &nbsp;
                                        <i className="fa fa-rotate-left"></i>
                                    </Link>
                                </div>
                                }
                            </div>
                        </main> 
                    }
                <Footer />
                <WhatsApp />
            </Fragment>
        );
    }
}