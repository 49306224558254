import React, {Fragment, useEffect, useState} from 'react';
import { FiMapPin } from 'react-icons/fi';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import Leaflet from 'leaflet';
import 'leaflet.fullscreen/Control.FullScreen';

import location from '../../../../assets/images/icons/marker.svg';

import './styles.css';


const markerIcon = Leaflet.icon({
    iconUrl: location,
    iconSize:[40, 40],
    iconAnchor:[20, 40],
    popupAnchor:[140, 18]
});

export default function Details(props) {
    if (!props.imovel.area) {
        props.imovel.area = (props.imovel.area_util ? props.imovel.area_util : '0');
    }

    const [viewMap, setViewMap] = useState(false);
    const [imovel, setImovel] = useState(props.imovel)

    const handleMap = () => { setViewMap(true) };

    useEffect(()=> {
        setImovel(props.imovel);
    }, [props.imovel])

    return (
        <Fragment>
            <div className="box box-details">
                <span>{imovel.modalidade}</span> 
                <h1>
                    {`
                        ${imovel.tipo} de 
                        ${imovel.area == '0' ? imovel.area_total.toString() : imovel.area}m² no bairro
                        ${imovel.bairro}
                    `}
                </h1>
                <address><FiMapPin/> {imovel.bairro}, {imovel.cidade} - {imovel.estado}</address>
                <h2>{imovel.modalidade === 'Venda' ? 'R$' : ''}  {imovel.valor.replace(',00', '')}</h2>

                <ul>
                    <li>
                        <i className="fas fa-vector-square"/>
                        {imovel.area == '0' ? imovel.area_total : imovel.area}m²   
                    </li>
                    {imovel.suites > 1 && imovel.suites !== imovel.quartos && imovel.quartos !== 0 &&
                        <li>
                            <i className="fas fa-bed"/>
                            {imovel.quartos} dormitórios,<br/> sendo {imovel.suites} suítes
                        </li>
                    }
                    {imovel.suites === 1 && imovel.suites !== imovel.quartos && imovel.quartos !== 0 &&
                        <li>
                            <i className="fas fa-bed"/>
                            {imovel.quartos} dormitórios,<br/> sendo {imovel.suites} suíte
                        </li>
                    }
                    {imovel.suites === imovel.quartos && imovel.quartos !== 0 &&
                        <li>
                            <i className="fas fa-bed"/>
                            {imovel.suites} suítes
                        </li>
                    }
                    {imovel.quartos === 0 && imovel.suites > 0 &&
                        <li>
                            <i className="fas fa-bed"/>
                            {imovel.suites} suítes
                        </li>
                    }
                    {imovel.quartos === 0 && imovel.suites === 0 &&
                        <li>
                            <i className="fas fa-bed"/>
                            {imovel.suites} dormitórios
                        </li>
                    }
                    <li>
                        <i className="fas fa-bath"/> 
                        {imovel.banheiros} wc's
                    </li>
                    <li>
                        <i className="fas fa-car"/>
                        {imovel.vagas} vagas
                    </li>
                    <li>
                        <i className="fas fa-qrcode"/>
                        Cód. {imovel.codigo} 
                    </li>
                </ul> 
            </div>

            <div className="box box-features">
                <h2>Detalhes do imóvel</h2>
                <ul>
                    {imovel.caracteristicas.map((item, index) => ( 
                        <li key={index}>                            
                            {item.nome}
                        </li>
                    ))}                            
                </ul>
            </div>

            <div className="box box-description">
                <h2>Descrição do imóvel</h2>
                <p>
                    {imovel.descricao}
                </p>
            </div>

            { imovel.condominio && imovel.condominio.length > 0 &&
            <div className="box box-features">
                <h2>Detalhes do edifício</h2>
                <ul>
                    {imovel.condominio.map((item, index) => ( 
                        <li key={index}>
                            <i className="fas fa-check"/>&nbsp;
                            {item.nome}
                        </li>
                    ))}                            
                </ul>
            </div>
            }
            <div className="box box-location">
                <h2>Localização do imóvel</h2>
                <div className="block-map">
                    <div className="content-map">                                    
                        <span onClick={()=>handleMap()}>Ver no mapa</span>
                    </div>

                    {viewMap ? 
                        <Map 
                            center={imovel.latitude === '' ? [-3.7487678242054603, -38.53816582049576] : [imovel.latitude, imovel.longitude]}
                            zoom={imovel.latitude === '' ? 12 : 15}
                            fullscreenControl={true}
                            scrollWheelZoom={false}
                            style={{ width: '100%', height: '100%' }}
                        >
                            <TileLayer
                                url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`}
                            />

                            <Marker 
                                position={imovel.latitude === '' ? [0,0] : [imovel.latitude, imovel.longitude]}
                                icon={markerIcon}
                            >
                                <Popup closeButton={false} maxWidth={210} className="popup-map">
                                    {imovel.endereco} <br/>
                                    {imovel.bairro} {imovel.cidade} - {imovel.estado}
                                </Popup>
                            </Marker>

                        </Map> : ''}   
                </div>
            </div>
        </Fragment>
    );
}