import React, { Fragment, Component } from 'react';
import Helmet from 'react-helmet';
import Header from '../../components/Layout/Header';
import Breadcrumbs from './Breadcrumbs';
import Topo from './Topo';
import Content from './Content';
import Footer from '../../components/Layout/Footer';
import WhatsApp from '../../components/Layout/Contato/Whatsapp';

import utils from '../../helpers/utils';
import Services from './ServicesA';
import fields from './fields';

export default class Home extends Component {
    state = {
        ...fields,
        intervalId: 0
    };

    constructor(props) {
        super(props);
        this._services = new Services();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let params = (nextProps.match.params.filtros ? nextProps.match.params.filtros : '');
        let params_filtros = {};

        if (params !== '') {
            let filtros = '';
            let padrao = [];
            filtros = params.split('+');

            for (let i in filtros) {                
                padrao = filtros[i].split('-');
                params_filtros[padrao[1]] = padrao[0];                
            }
        }        

        if (
            nextProps.match.params.modalidade === prevState.modalidade ||
            nextProps.match.params.tipo === prevState.tipo ||
            nextProps.match.params.endereco === prevState.endereco) {                
            return null;
            
        }

        return {
            modalidade: nextProps.match.params.modalidade,
            tipo: nextProps.match.params.tipo,
            endereco: nextProps.match.params.endereco,
            filtros: params_filtros          
        };        
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.modalidade !== this.props.match.params.modalidade ||
            prevProps.match.params.tipo !== this.props.match.params.tipo) {
            this.setState({
                ...this.state,
                modalidade: this.props.match.params.modalidade,
                tipo: this.props.match.params.tipo,
                endereco: this.props.match.params.endereco,
                offset: 0,
                page: 1                
            }, () => {this.buscar()})
        }               
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        this.setState({loadingContent: true});
        this._services.busca(
            this.state.modalidade,
            this.state.tipo,
            this.state.endereco,
            this.state.filtros,
            this.state.page,
            this.state.order,
            this.state.limit,
            this.state.offset,
            this.state.caracSelecionados,
            this.state.caracSelecionadosCondominio
        )
        .then(response => {
            this.setState({
                imoveis: response.imoveis,
                total: response.total,
                loadingContent: false
            });
        })
        .catch(e => console.log(e));

        this._services.getbuscaEndereco(this.state.modalidade,'')
            .then(response => this.setState({
                bairros: response.bairros,
                cidades: response.cidades })
        );

        this._services.getTiposImoveisOptions(this.state.modalidade)
            .then(tipos => {
                this.setState({tipos: tipos ? tipos : [{'name': 'Selecione','value': 0}]});
            }
        );
       
        this._services.getCaracteristicasImovel(this.state.modalidade, this.state.tipo)
            .then(options => this.setState({caracteristicas: options})
        );    
        
        this._services.getCaracteristicasCondominio(this.state.modalidade, this.state.tipo)
            .then(options => this.setState({caracsCondominio: options})
        );        
    }   

    buscar = () => {
        window.scrollTo(0, 0);
        this.setState({loadingContent: true});
        this._services.busca(
            this.state.modalidade, 
            this.state.tipo, 
            this.state.endereco, 
            this.state.filtros, 
            this.state.page, 
            this.state.order, 
            this.state.limit, 
            this.state.offset, 
            this.state.caracSelecionados, 
            this.state.caracSelecionadosCondominio
        )
        .then(response => {          
            this.props.history.push('/'+this.state.modalidade.toLowerCase()+'/'+utils.transliterate(this.state.tipo)+'/'+this.state.endereco.toLowerCase()+this.adicionarFiltros());
            this.setState({
                imoveis: response.imoveis,
                total: response.total,
                loadingContent: false
            });
        })
        .catch(e => console.log(e));

        this._services.getbuscaEndereco(this.state.modalidade,'')
            .then(response => this.setState({
                bairros: response.bairros,
                cidades: response.cidades })
        );

        this._services.getTiposImoveisOptions(this.state.modalidade)
            .then(tipos => {
                this.setState({tipos: tipos ? tipos : [{'name': 'Selecione','value': 0}]});
            }
        );

        this._services.getCaracteristicasImovel(this.state.modalidade, this.state.tipo)
            .then(options => this.setState({caracteristicas: options})
        );    
        
        this._services.getCaracteristicasCondominio(this.state.modalidade, this.state.tipo)
            .then(options => this.setState({caracsCondominio: options})
        );        
         
    };

    adicionarFiltros() {
        let filtros = this.state.filtros;
        let resultado = "";       
        for (let i in filtros) {
                if (filtros[i] !== '' && filtros[i] !== 0 ) {
                if (i !== 'order'){ resultado += filtros[i] + '-' + i + '+'}
            }
        }

        return '/'+resultado.substr(0,(resultado.length - 1));
    }

    handleSelect = (e) => {
        this.setState({
            [e.target.name]: (e.target.value !== null ? e.target.value : '')                      
        }, () => {
            this.buscar();
        });
    };

    handleSelectFilters = (e) => {
        this.setState({
            filtros: {
                ...this.state.filtros,
                [e.target.name]: (e.target.value !== null ? e.target.value : '')
            },
            offset: 0
        }, () => this.buscar());
    };

    handleChange = (e) => {
        this.setState({
            filtros: {
                ...this.state.filtros,
                [e.target.name]: (e.target.value !== null ? e.target.value.replace('.','').replace('.','').replace(',00','') : '')
            }
        });
    };

    handleEnter = (e) => {
        if (e.key === 'Enter') {
            this.setState({              
                offset: 0
            }, () => {
                this.buscar();
            });
        }
    };

    handleCheckedList = (e) => {
        let list = this.state.caracSelecionados;
        (
            e.target.checked ? 
            list.push({name: e.target.name , value: e.target.value }) : 
            list.splice(list.findIndex(index => index.value === e.target.value), 1)
        )
        this.setState({
            caracSelecionados: list,
            offset: 0
        }, () =>this.buscar())
    }

    handleCheckedListCondominio = (e) => {
        let list = this.state.caracSelecionadosCondominio;        
        (
            e.target.checked ? 
            list.push({name: e.target.name , value: e.target.value }) : 
            list.splice(list.findIndex(index => index.value === e.target.value), 1)
        )
        this.setState({
            caracSelecionadosCondominio: list,
            offset: 0
        }, () =>this.buscar())
    }

    handlePaginacao = (name, value) => {
        this.setState({
            [name]: value
        }, () => {
            this.buscar();
        });
    };

    handlePageChange = (pageNumber) => {    
        this.setState({page: pageNumber});
        this.handlePaginacao('offset', ((this.state.limit * pageNumber) - this.state.limit))
    }

    render() {
        return (
            <Fragment>
                <Helmet 
                title={
                    `Imoveis ${this.state.modalidade === 'aluguel' ? 'para alugar': 'à venda'} | 
                    ${process.env.REACT_APP_NAME}`} 
                />

                <Header />
                <main>
                    <Breadcrumbs params={this.props.match.params}/>
                    <Topo order={this.state.order} handleSelect={this.handleSelect}/>
                    <Content
                        {...this.state}
                        buscar={this.buscar}
                        handleSelect={this.handleSelect}
                        handleChange={this.handleChange}
                        handleEnter={this.handleEnter}
                        handleSelectFilters={this.handleSelectFilters}
                        handleCheckedList={this.handleCheckedList}
                        handleCheckedListCondominio={this.handleCheckedListCondominio}
                        handlePaginacao={this.handlePaginacao}
                        handlePageChange={this.handlePageChange}  
                    />
                </main>
                <Footer />
                <WhatsApp />
            </Fragment>
        );
    }
}