import React from 'react';

import './styles.css';

export default function Widget() {
    return (
        <div id="widget-container">
            <div className="container">
                <div className="content">
                    <h2>O que já fizemos</h2>
                    <p>
                        Procuramos sempre criar boas oportunidades de negócios tanto 
                        para quem vende como para quem compra ou aluga em qualquer local de Fortaleza
                    </p>
                    <ul>
                        <li><span>3200+</span> Vendas de imóveis</li>
                        <li><span>2200+</span> Aluguéis de imóveis</li>
                        <li><span>20+</span> anos de experiência</li>
                        <li><span>99,9%</span> Clientes satisfeitos</li>
                    </ul>
                </div>
            </div>               
        </div>
    );
}