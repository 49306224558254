import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'
import { Form, Col, Input, Button } from "reactstrap";

import './styles.css';

export default function FormCode() {

    const [modality, setModality] = useState('venda');
    const [code, setCode] = useState('');

    const history = useHistory();

    const handleSubmit = (event) => {
        event.preventDefault()
        history.push(`/detalhes/${modality}/${code}`);
    }

    return (
        <Form className='form-box' onSubmit={event => handleSubmit(event)}>
            <Col className='col-modality'>
                <Input        
                    type="select"
                    name="modalidade"
                    onChange={(event) => setModality(event.target.value)}
                    value={modality} >

                    <option value='aluguel'>Aluguel</option>
                    <option value='venda'>Comprar</option>
                </Input>
            </Col>
            <Col className='col-codigo'>
                <Input
                    type="text"
                    name="codigo"
                    autoFocus={true}
                    placeholder="Digite o código imóvel"
                    required={true}
                    onChange={(event)=>setCode(event.target.value)}
                    value={code} >
                </Input>
            </Col>
            <Col className='col-btn'>
                <Button type="submit">Buscar</Button>
            </Col>            
        </Form>
    );
}