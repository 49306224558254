import React from 'react';
import FormSearch from '../FormSearch';

import './styles.css';

export default function SidebarForm(props) {
    return (
        <div id="sidebar-form">
            <i className="fa fa-times-circle-o" onClick={props.close}/> 
            <FormSearch {...props} close={props.close}/>
        </div>
    )
}