import React from 'react';

import './styles.css';

export default function Headline({title, text }) {
    return (
        <div className="headline">
            <h2> {title} </h2>
            <p> {text} </p>
        </div>
    );
}