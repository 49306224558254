import React, { Fragment, Component } from 'react';
import Helmet from 'react-helmet';
import Header from '../../components/Layout/Header';
import Breadcrumbs from './Breadcrumbs';
import Topo from './Topo';
import Benefits from '../Home/Benefits';
import Spread from './Spread';
import Analysis from './Analysis';
import Footer from '../../components/Layout/Footer';
import WhatsApp from '../../components/Layout/Contato/Whatsapp';

export default class PropertyRegistration extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <Fragment>
                <Helmet title={`Cadastre seu imóvel | ${process.env.REACT_APP_NAME}`} />
                <Header />
                <main>
                    <Breadcrumbs />
                    <Topo />
                    <Benefits />
                    <Analysis />
                    <Spread />
                </main>
                <Footer />
                <WhatsApp />
            </Fragment>
        );
    }
}