import React from 'react';

import './styles.css';

export default function Analysis() {
    return (
        <section id='analysis'>
            <div className="container">
                <h2>Somos referência na cidade em <strong>negociação imobiliária</strong></h2>     
                <p>
                    Dispomos de profissionais qualificados para assistir o 
                    cliente em seu investimento proporcionando todo suporte 
                    jurídico necessário, conferindo total segurança em todo o
                    processo de seu imóvel.
                </p>       
            </div>            
        </section>
    );
}