import React from 'react';
import MapResult from '../MapResult';

import './styles.css';

export default function SidebarMap(props) {
    return (
        <div id="sidebar-map">
            <i className="fa fa-times-circle-o" onClick={props.close}/> 
            <MapResult list={props.imoveis}/>
        </div>
    )
}