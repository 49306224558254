/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { FiPlus, FiMinus } from 'react-icons/fi';
import { Form, Col, Input, Button } from "reactstrap";
import Select2 from 'react-select2-wrapper';
import NumberFormat from "react-number-format";
import ApiService from './../../../services/api';
import RequestUtils from '../../../config/requestUtils';

import initialState from './../fields';
import utils from './../../../helpers/utils';

import './styles.css';

export default function FormSearch() {
    const [state, setState] = useState(initialState);    
    const [options, setOptions] = useState([]);
    const [cidades, setCidades] = useState([]);
    const [bairros, setBairros] = useState([]);
    const [openSearchPlus, setOpenSearchPlus] = useState(false); 
    let api = ApiService.create(state.modalidade);   

    const history = useHistory();

    const listCidades = cidades.map((cidade) => (
        { text: utils.capitalize(cidade.text), id: utils.capitalize(cidade.id) }
    ));

    const listBairros = bairros.map((bairro) => (
        { text: utils.capitalize(bairro.text), id: utils.capitalize(bairro.id) }
    )); 

    useEffect(() => {
        handleOnChangeModality()
    },[state.modalidade]);


    const handleOnChangeModality =  async() => {  
        api = ApiService.create(state.modalidade);   

        await api.get(RequestUtils.methodResolver('tipos', state.modalidade)).then(
            response => {
                setOptions(response.data.options);
            }
        );

        await api.get(RequestUtils.methodResolver('buscaEndereco', state.modalidade)).then(
            response => {
                setCidades(response.data.resultado.cidades); 
                setBairros(response.data.resultado.bairros);
            }            
        );
    }

    const handleOnChange = (event) => {
        setState({
            ...state,
            filters: {
                ...state.filters,
                [event.target.name]: (event.target.value !== null ? event.target.value.replace('.','').replace('.','').replace(',00','') : '')
            }
        });
    };

    const handleOnSelectModality = (event) => {        
        setState({
            ...state,
            [event.target.name]: event.target.value
        });
    }

    const handleOnSelect = (event) => {        
        setState({
            ...state,
            [event.target.name]: event.target.value
        });
    }

    const handleSelectFilters = (event) => {
        setState({
            ...state,
            filters: {
                ...state.filters,
                [event.target.name]: (event.target.value !== null ? event.target.value : '')
            }
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        history.push(`/${state.modalidade}/${utils.transliterate(state.tipo)}/${utils.transliterate(state.endereco)}${handleFilters()}`);
    };

    const handleFilters = () => {
        let filters = state.filters;
        let resultado = "";
        
        for (let i in filters) {
            if (filters[i] !== ''){
                if (i !== 'order'){ resultado += filters[i] + '-' + i + '+';}
            }
        }
        return '/'+ resultado.substr(0,(resultado.length - 1));
    }

    const handleSearchPlus = () => {
        setOpenSearchPlus(!openSearchPlus);        
        const element = document.querySelector('#search-plus-box');

        if (!openSearchPlus) {
            element.classList.add('open-search-plus');
        } else {
            element.classList.remove('open-search-plus');
        }
    };    
    
    return (
        <Form className='form-box' onSubmit={(event) => handleSubmit(event)}>
            <Col className='col-modality'>
                <Input        
                    type="select"
                    name="modalidade"
                    onChange={(event)=>handleOnSelectModality(event)}
                    value={state.modalidade} >

                    <option value='aluguel'>Aluguel</option>
                    <option value='venda'>Comprar</option>
                </Input>
            </Col>

            <Col className='col-tipo'>
                <Input
                    type="select"
                    name="tipo"
                    onChange={(event)=>handleOnSelect(event)}
                    value={state.tipo} >
                    
                    {options.map((tipo, index) => (
                        <option key={index} value={tipo.value}>{tipo.name}</option>
                    ))}
                </Input>
            </Col>

            <Col>
                <Select2                            
                    name="endereco"
                    data={[
                        { text: 'Cidades',
                            children: listCidades,
                        },
                        { text: 'Bairros',
                            children: listBairros,
                        }
                    ]}
                    options={{
                        placeholder: 'Selecione um bairro ou cidade',
                    }}
                    value={state.endereco}
                    onSelect={(event) => handleOnSelect(event)}                    
                    required={true}
                />
            </Col>
            <Col className='col-btn-location'>
                <Button type="submit">Buscar</Button>
                <Button 
                    id="search-plus" 
                    type="button" 
                    onClick={handleSearchPlus} > 
                    {!openSearchPlus ? <FiPlus/> : <FiMinus/>} 
                </Button>
            </Col>             

            <div id="search-plus-box">
                <hr/> 
                <div className="line">
                    <Col className='col-plus'>
                        <Input        
                            type="select"
                            name="quartos"
                            onChange={(event)=>handleSelectFilters(event)} >

                            <option value={''}>Quartos</option>
                            <option value={1}>1+ quartos</option>
                            <option value={2}>2+ quartos</option>
                            <option value={3}>3+ quartos</option>
                            <option value={4}>4+ quartos</option>
                            <option value={5}>5+ quartos</option>
                        </Input>
                    </Col>
                    <Col className='col-plus'>
                        <Input        
                            type="select"
                            name="suites"
                            onChange={(event)=>handleSelectFilters(event)} >

                            <option value={''}>Suítes</option>
                            <option value={1}>1+ suítes</option>
                            <option value={2}>2+ suítes</option>
                            <option value={3}>3+ suítes</option>
                            <option value={4}>4+ suítes</option>
                            <option value={5}>5+ suítes</option>
                        </Input>
                    </Col>
                    <Col className='col-plus'>
                        <Input        
                            type="select"
                            name="banheiros"
                            onChange={(event)=>handleSelectFilters(event)} >

                            <option value={''}>Banheiros</option>
                            <option value={1}>1+ banheiros</option>
                            <option value={2}>2+ banheiros</option>
                            <option value={3}>3+ banheiros</option>
                            <option value={4}>4+ banheiros</option>
                            <option value={5}>5+ banheiros</option>
                        </Input>
                    </Col>
                    <Col className='col-plus'>
                    <Input        
                        type="select"
                        name="vagas"
                        onChange={(event)=>handleSelectFilters(event)} >

                        <option value={''}>Vagas</option>
                        <option value={1}>1+ vagas</option>
                        <option value={2}>2+ vagas</option>
                        <option value={3}>3+ vagas</option>
                        <option value={4}>4+ vagas</option>
                        <option value={5}>5+ vagas</option>
                    </Input>
                </Col>
                </div>
                <div className="line">
                    <Col className='col-plus'>
                        <NumberFormat
                            name="valorInicial"
                            thousandSeparator='.'
                            decimalSeparator=','
                            fixedDecimalScale={true}
                            allowNegative={false}
                            value={state.filters.valorInicial}
                            onChange={event => handleOnChange(event)}
                            className="form-control"                
                            placeholder="Valor inicial R$"
                        />
                    </Col>
                    <Col className='col-plus'>
                        <NumberFormat
                            name="valorFinal"
                            thousandSeparator='.'
                            decimalSeparator=','
                            fixedDecimalScale={true}
                            allowNegative={false}
                            value={state.filters.valorFinal}
                            onChange={event => handleOnChange(event)}
                            className="form-control"                
                            placeholder="Valor final R$"
                        />
                    </Col>
                    <Col className='col-plus'>
                        <NumberFormat
                            name="areaInicial"
                            thousandSeparator='.'
                            decimalSeparator=','
                            fixedDecimalScale={true}
                            allowNegative={false}
                            value={state.filters.areaInicial}
                            onChange={event => handleOnChange(event)}
                            className="form-control"                
                            placeholder="Área inicial m²"
                        />
                    </Col>
                    <Col className='col-plus'>
                    <NumberFormat
                        name="areaFinal"
                        thousandSeparator='.'
                        decimalSeparator=','
                        fixedDecimalScale={true}
                        allowNegative={false}
                        value={state.filters.areaFinal}
                        onChange={event => handleOnChange(event)}
                        className="form-control"                
                        placeholder="Área final m²"
                    />
                </Col>
                </div>
            </div>
        </Form>
    );
}