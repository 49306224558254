import React from 'react';
import { Form, Input } from "reactstrap";
import Select2 from 'react-select2-wrapper';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import NumberFormat from "react-number-format";

import utils from '../../../../helpers/utils';

import './styles.css';


export default function FormSearch(props) {
    const closeSearch = () => {
        props.buscar();
        props.close();
    };

    const listBairros = props.bairros.map((bairro) => (
        { text: utils.capitalize(bairro.text), id: utils.capitalize(bairro.id) }
    ));

    const listCidades = props.cidades.map((cidade) => (
        { text: utils.capitalize(cidade.text), id: utils.capitalize(cidade.id) }
    ));

    return(
        
        <Form id="form-result">
            <div className="box-especial">
                <Input                         
                    type="select"
                    name="modalidade"
                    onChange={(event)=>props.handleSelect(event)}
                    value={props.modalidade} >

                    <option value='aluguel'>Aluguel</option>
                    <option value='venda'>Venda</option>
                </Input>               

                <Input
                    type="select"
                    name="tipo"
                    onChange={(event)=>props.handleSelect(event)}
                    value={props.tipo} >
                    
                    {props.tipos.map((tipo, index) => (
                        <option key={index} value={tipo.value}>{tipo.name}</option>
                    ))}
                </Input>

                <Select2                            
                    name="endereco"
                    data={[
                        { text: 'Cidades',
                            children: listCidades,
                        },
                        { text: 'Bairros',
                            children: listBairros,
                        }
                    ]}
                    options={{
                        placeholder: 'Selecione um bairro ou cidade',
                    }}
                    value={props.endereco}
                    onSelect={(event)=>props.handleSelect(event)}                    
                    required={true}
                />

                <fieldset style={{marginTop: '1rem'}}>
                    <label>Preço R$</label>
                    <NumberFormat
                        name="valorInicial"
                        thousandSeparator='.'
                        decimalSeparator=','
                        fixedDecimalScale={true}
                        allowNegative={false}
                        value={props.filtros.valorInicial}
                        onKeyPress={event => props.handleEnter(event)}
                        onChange={event => props.handleChange(event)}
                        className="form-control"                
                        placeholder="R$"
                    />

                    <NumberFormat
                        name="valorFinal"
                        thousandSeparator='.'
                        decimalSeparator=','
                        fixedDecimalScale={true}
                        allowNegative={false}
                        value={props.filtros.valorFinal}
                        onKeyPress={event => props.handleEnter(event)}
                        onChange={event => props.handleChange(event)}
                        className="form-control"                
                        placeholder="R$"
                    />
                </fieldset>

                <fieldset>
                    <label>Área m²</label>

                    <NumberFormat
                        name="areaInicial"
                        thousandSeparator='.'
                        decimalSeparator=','
                        fixedDecimalScale={true}
                        allowNegative={false}
                        value={props.filtros.areaInicial}
                        onKeyPress={event => props.handleEnter(event)}
                        onChange={event => props.handleChange(event)}
                        className="form-control"                
                        placeholder="m²"
                    />

                    <NumberFormat
                        name="areaFinal"
                        thousandSeparator='.'
                        decimalSeparator=','
                        fixedDecimalScale={true}
                        allowNegative={false}
                        value={props.filtros.areaFinal}
                        onKeyPress={event => props.handleEnter(event)}
                        onChange={event => props.handleChange(event)}
                        className="form-control"                
                        placeholder="m²"
                    />
                </fieldset>
            </div>

            <fieldset>
                <label>Quartos</label>    
                <Input
                    name="quartos"                      
                    type="select"
                    value={props.filtros.quartos}
                    onChange={(e) => props.handleSelectFilters(e)}
                >
                    <option value={''}>-</option>
                    <option value={1}>1+</option>
                    <option value={2}>2+</option>
                    <option value={3}>3+</option>
                    <option value={4}>4+</option>
                    <option value={5}>5+</option>
                </Input>
            </fieldset>

            <fieldset>
                <label>Suítes</label>    
                <Input
                    name="suites"                      
                    type="select"
                    value={props.filtros.suites}
                    onChange={(e) => props.handleSelectFilters(e)}
                >
                    <option value={''}>-</option>
                    <option value={1}>1+</option>
                    <option value={2}>2+</option>
                    <option value={3}>3+</option>
                    <option value={4}>4+</option>
                    <option value={5}>5+</option>
                </Input>
            </fieldset>

            <fieldset>
                <label>Banheiros</label>    
                <Input
                    name="banheiros"                      
                    type="select"
                    value={props.filtros.banheiros}
                    onChange={(e) => props.handleSelectFilters(e)}
                >
                    <option value={''}>-</option>
                    <option value={1}>1+</option>
                    <option value={2}>2+</option>
                    <option value={3}>3+</option>
                    <option value={4}>4+</option>
                    <option value={5}>5+</option>
                </Input>
            </fieldset>

            <fieldset>
                <label>Vagas</label>    
                <Input
                    name="vagas"                      
                    type="select"
                    value={props.filtros.vagas}
                    onChange={(e) => props.handleSelectFilters(e)}
                >
                    <option value={''}>-</option>
                    <option value={1}>1+</option>
                    <option value={2}>2+</option>
                    <option value={3}>3+</option>
                    <option value={4}>4+</option>
                    <option value={5}>5+</option>
                </Input>
            </fieldset>

            {props.caracteristicas !== null && props.caracteristicas.length > 0 ? <hr/> : ''}
            {props.caracteristicas !== null && props.caracteristicas.length > 0 ?
                <div className="box-feature-list">
                    <label>Imóvel</label>

                    {props.caracteristicas.map((item, index) => (
                        <FormControlLabel
                            key={index}
                            control={
                                <Checkbox
                                    name={item.name}
                                    value={item.value}
                                    color="primary"
                                    onChange={(e) => props.handleCheckedList(e)}
                                />}
                            label={item.name}
                        />
                    ))}
                </div>
            : ''}

            {props.caracsCondominio !== null && props.caracsCondominio.length > 0 ? <hr/> : ''}
            {props.caracsCondominio !== null && props.caracsCondominio.length > 0 ?
                <div className="box-feature-list">
                    <label>Infraestrutura</label>
                    {props.caracsCondominio.map((item, index) => (
                        <FormControlLabel
                        key={index}
                        control={
                            <Checkbox
                                name={item.name}
                                value={item.value}
                                color="primary"
                                onChange={(e) => props.handleCheckedListCondominio(e)}
                            />}
                        label={item.name}
                        />
                    ))}
                </div>
            :''}
            
            <div className="btn-wrap">
                <button type="button" onClick={closeSearch} className="btn btn-primary">Enviar dados</button>
            </div> 
        </Form>
    );
}