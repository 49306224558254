import React from 'react';
import Pagination from "react-js-pagination";
import FormSearch from './FormSearch';
import SidebarForm from './SidebarForm';
import SidebarMap from './SidebarMap';
import ImovelCard from '../../../components/Cards/ImovelCard';
import TitleLoader from '../../../components/TitleLoader';
import LoadingMask from "react-loadingmask";
import MapResult from '../Content/MapResult';
import utils from '../../../helpers/utils';

import './styles.css';

export default class Content extends React.Component {
    closeSidebarForm = () => {
        document.querySelector('#overlay').classList.remove('overlay');
        document.querySelector('#sidebar-form').classList.remove('open-sidebar-form');
    }

    closeSidebarMap = () => {
        document.querySelector('#overlay').classList.remove('overlay');      
        document.querySelector('#sidebar-map').classList.remove('open-sidebar-map');      
    }

    render() {
        let title = '';
        if (this.props.total > 0) {
            title =`${this.props.total} imóve${this.props.total > 1 ? 'is':'l'} do tipo
                    ${utils.retiraHifen(this.props.tipo)} ${this.props.modalidade === 'aluguel' ? 'para alugar':'à venda'} em 
                    ${utils.capitalize(utils.retiraHifen(this.props.endereco))}`;
        } else {
            title = 'Não foram encontrados imóveis para a busca realizada';
        }

        let paginacao;
        if (this.props.total > this.props.limit) {
            paginacao =             
                <div className="content-pagination">
                    <nav>
                        <LoadingMask loading={this.props.loadingContent} >               
                        <Pagination
                            hideDisabled
                            prevPageText='Anterior'
                            nextPageText='Próximo'
                            firstPageText='Início'
                            lastPageText='Fim'
                            activePage={this.props.page}
                            itemsCountPerPage={this.props.limit}
                            totalItemsCount={parseInt(this.props.total)}
                            onChange={this.props.handlePageChange}/>
                         </LoadingMask>            
                    </nav>
                </div>
        } else {
            paginacao = ''
        }

        return (
            <div id="search-result">
                <div id="overlay" onClick={this.closeSidebarForm}></div>

                <SidebarForm close={this.closeSidebarForm} {...this.props}/>
                <SidebarMap close={this.closeSidebarMap} imoveis={this.props.imoveis}/>

                <div className="container">
                    <h1>{ this.props.loadingContent ? '' : title }</h1>

                    <div className="search-result-wrapper">
                        <div id="form-sidebar" className="hidden-form">
                            <FormSearch {...this.props} />
                        </div>

                        <div id="result-card">
                            <div className="result-card-content">
                                { this.props.loadingContent ? <TitleLoader /> :
                                
                                    this.props.imoveis.map((imovel, index) => (
                                        <div key={index} className="item">
                                            <ImovelCard data={imovel} icon="heart"/>
                                        </div>
                                    ))
                                }
                            </div>

                            { paginacao }                       
                        </div>

                        <div id="result-map" className="hidden-map">
                            <MapResult list={this.props.imoveis} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}