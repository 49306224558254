import React from 'react';
import Headline from '../../../components/Headeline';

import sale from '../../../assets/images/icons/sale.svg';
import ofering from '../../../assets/images/icons/ofering.svg';
import handshake from '../../../assets/images/icons/handshake.svg';
import checkList from '../../../assets/images/icons/check-list.svg';
import browser from '../../../assets/images/icons/browser.svg';
import consultant from '../../../assets/images/icons/consultant.svg';

import './styles.css';

export default function Benefits() {
    return (
        <section id='benefits'>
            <Headline 
                title="Porque escolher a DMV Imóveis" 
                text="Somos diferentes porque temos anos de experiência e 
                nossa abordagem é trabalhar diretamente com nossos clientes 
                em tudo que desenvolvemos"
            />

            <div className="container">
                <article>
                    <img src={sale} alt="Venda o seu imóvel"/>
                    <h2>Venda o seu imóvel</h2>
                    <p>
                        A DMV imóveis possui larga atuação no mercado imobiliário cearense nos mais diversos setores: 
                        residencial, comercial, veraneio e built to suit. Garantindo-lhe conhecimento de mercado e
                        e segurança jurídica.
                    </p>
                </article>
                <article>
                    <img src={ofering} alt="Obtenha a melhor oferta"/>
                    <h2>Obtenha a melhor oferta</h2>
                    <p>
                        Com nossas divulgações e com nosso trabalho e experiência, buscaremos sempre a melhor oferta e 
                        as melhores condições na venda do seu imóvel.
                    </p>
                </article>
                <article>
                    <img src={handshake} alt="Feche o negócio rápdio"/>
                    <h2>Feche o negócio rápido</h2>
                    <p>
                        Com alcance das nossas divulgações e boa relação dos sócios da DMV Imóveis, com experiência e as 
                        melhores praticas, buscaremos sempre a segurança e rapidez na venda do seu imóvel.
                    </p>
                </article>
                <article>
                    <img src={checkList} alt="Avaliação gratuita"/>
                    <h2>Avaliação gratuita</h2>
                    <p>
                        Com a DMV Imóveis, você terá garantia e segurança de uma avaliação com critérios e parâmetros 
                        reais de uma boa avaliação, sempre dentro de boas práticas e usando os melhores critérios para o 
                        valor justo do seu imóvel.
                    </p>
                </article>
                <article>
                    <img src={browser} alt="Anucie em nosso site"/>
                    <h2>Anuncie em nosso site</h2>
                    <p>
                        Com investimentos constantes em nosso site e demais plataformas de venda, tais como ZAP, VIVA 
                        REAL e OLX, teremos sempre a mais larga abrangência para divulgação segura e eficaz do seu imóvel.
                    </p>
                </article>
                <article>
                    <img src={consultant} alt="Consultoria imobiliária"/>
                    <h2>Consultoria imobiliária</h2>
                    <p>
                        Experiência de mais de 15 anos, relacionamento forte com o mercado, jurídico próprio e atuante, 
                        a DMV Imóveis tem todas as ferramentas para lhe prestar uma consultoria de qualidade e garantia 
                        de um negócio seguro.
                    </p>
                </article>
            </div>
        </section>
    );
}